<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        M&A Compensation and Benefits Integration
      </h2>

      <img
        src="../../assets/img/ma_banner.jpg"
        alt="M&A Compensation and Benefits Integration Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="pt-4 pb-2">
          A huge M&A transaction raises many HR and compensation & benefits
          issues that can only be resolved with specialised knowledge and
          experience. Potential areas of focus include:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Integration of multiple salary structures and incentive plans</li>
          <li>Equity compensation</li>
          <li>Employee benefits</li>
          <li>Job titles alignment</li>
          <li>Different career paths</li>
        </ul>
        <p class="pb-4 pt-2">
          To reinforce the potential human synergy arise from the M&A,
          MRC works closely with organisations to provide practical
          C&B solutions that fit within the business strategy of the transaction.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
